import assets from './api/assets'
import { PORT } from './api/consts'

const isDev = process.env.NODE_ENV === 'development'
const muteAssetWarnings = process.env.MUTE_ASSET_WARNINGS === '1'

const gcsStaticBucket =
  typeof window !== 'undefined' ?
    window.serverConfig?.gcsStaticBucket
  : process.env.GCS_STATIC_BUCKET

export const hasAsset = (path: string) => assets[path] !== undefined

export default function getAsset(
  path: string,
  options: { has2x?: boolean; absolute?: boolean } = {},
) {
  let revedPath = assets[path]

  if (options.has2x) {
    if (window.devicePixelRatio > 1) {
      const path2x = path.replace(/\.(\w+)$/, '@2x.$1')
      revedPath = assets[path2x] || revedPath
    }
  }

  if (revedPath == null && !muteAssetWarnings) {
    console.error('Failed to find reved asset:', path)
    return `/NOT-REVED/${path}`
  }

  if (isDev || !gcsStaticBucket) {
    return `${options.absolute ? `http://localhost:${PORT}` : ''}/${revedPath}`
  }

  return `https://${gcsStaticBucket}.storage.googleapis.com/${revedPath}`
}
