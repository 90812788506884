import React from 'react'
import fp from 'lodash/fp.js'
import { withProps } from 'recompose'

import getAsset from '~p/getAsset'

import { controlize } from '~p/client/control-tree'
import {
  debounceChange,
  hideWhenSingleOption,
  addNoneToOptions,
  respectVisibleOptions,
  uploadsEnhancer,
  limitChangeMaxLength,
} from '~p/client/component-enhancers'

import FileUpload from '~mizuno/client/components/organisms/FileUpload'
import SelectInput from '~mizuno/client/components/molecules/SelectInput'
import ContentBody from '~mizuno/client/components/atoms/ContentBody'
import TileSelect from '~mizuno/client/components/TileSelect'

import TextInput from '../components/TextInput'
import TextareaInput from '../components/TextareaInput'
import ToggleSection from './ToggleSection'

const TextInputControl = fp.compose(
  controlize,
  debounceChange,
  limitChangeMaxLength,
)(TextInput)

const TextareaInputControl = fp.compose(
  controlize,
  debounceChange,
  limitChangeMaxLength,
)(TextareaInput)

const fileUpload = uploadsEnhancer({ targetUrl: '/api/files' })
const imageUpload = uploadsEnhancer({
  targetUrl: '/api/images',
  accept: { 'image/*': [] },
  previewUrl: ({ id }) => `/api/images/${id}`,
})

const FileUploadControl = fp.compose(controlize, fileUpload)(FileUpload)

const ImageUploadControl = fp.compose(controlize, imageUpload)(FileUpload)

const TileSelectControl = fp.compose(
  controlize,
  respectVisibleOptions,
  hideWhenSingleOption,
  addNoneToOptions,
  withProps({ type: 'TEXT' }),
)(TileSelect)

const ColorSelectControl = fp.compose(
  withProps({ appendNone: true }),
  controlize,
  respectVisibleOptions,
  hideWhenSingleOption,
  addNoneToOptions,
  withProps({ type: 'COLOR' }),
)(TileSelect)

const FontSelectControl = fp.compose(
  controlize,
  respectVisibleOptions,
  hideWhenSingleOption,
  withProps({
    type: 'IMAGE',
    svgType: 'letteringFonts',
    classMods: ['noLabel'],
  }),
)(TileSelect)

const FontLayoutSelectControl = fp.compose(
  controlize,
  respectVisibleOptions,
  hideWhenSingleOption,
  withProps({ type: 'IMAGE', svgType: 'letteringLayouts' }),
)(TileSelect)

const FontTailSelectControl = fp.compose(
  controlize,
  respectVisibleOptions,
  hideWhenSingleOption,
  withProps({ type: 'IMAGE', svgType: 'letteringTails' }),
)(TileSelect)

const FabricSelectControl = fp.compose(
  controlize,
  respectVisibleOptions,
  hideWhenSingleOption,
  withProps({
    type: 'IMAGE',
    classMods: ['bgOpacity'],
    getUrl: (opt) => getAsset(`icons/fabricType/${opt.id}.jpg`),
  }),
)((props) => (
  <ToggleSection sectionId="fabric" primaryLabel="Fabric">
    {() => (
      <ContentBody classMods={['block']}>
        <TileSelect {...props} />
      </ContentBody>
    )}
  </ToggleSection>
))

const GraphicDesignSelectControl = fp.compose(
  controlize,
  respectVisibleOptions,
  hideWhenSingleOption,
  withProps({
    type: 'IMAGE',
    classMods: ['square', 'bgProduct'],
    getUrl: (opt, { garment }) =>
      getAsset(
        `volleyball/icons/designs/${garment.silhouetteId}-${garment.variantId}/${opt.id}.png`,
      ),
  }),
)(TileSelect)

const PatternSelectControl = fp.compose(
  withProps({ appendNone: true }),
  controlize,
  respectVisibleOptions,
  hideWhenSingleOption,
  addNoneToOptions,
  withProps({
    type: 'PATTERN',
    getUrl: (opt) =>
      getAsset(
        `icons/graphicTexture/${opt.id}.${
          opt.asset ? opt.asset.format : 'svg'
        }`,
      ),
  }),
)(TileSelect)

const SelectInputControl = fp.compose(
  controlize,
  respectVisibleOptions,
)(SelectInput)

export {
  TextInputControl,
  TextareaInputControl,
  TileSelectControl,
  ColorSelectControl,
  FontSelectControl,
  FontLayoutSelectControl,
  FontTailSelectControl,
  FabricSelectControl,
  GraphicDesignSelectControl,
  PatternSelectControl,
  FileUploadControl,
  ImageUploadControl,
  SelectInputControl,
}
