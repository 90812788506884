// See api/configureSentry for API Sentry.
import * as Sentry from '@sentry/browser'

// @ts-expect-error because ~c
import store from '~c/client/store'
// @ts-expect-error because ~c
import controlTree from '~c/client/controlTree'

window['Sentry'] = Sentry

const SENTRY_DSN = process.env.SENTRY_DSN

const environment = process.env.LOG_ENV ?? process.env.NODE_ENV ?? 'undefined' // FIXME: Also production for staging. This should be loaded from index.html's serverConfig.
const release = process.env.GIT_REV ?? 'undefined'

const tags = {
  service: 'client',
  customizer: process.env.CUSTOMIZER,
  sport: process.env.SPORT_ID,
  vendor: window.serverConfig?.vendorId,
}

console.log('Setting up Sentry integration', {
  SENTRY_DSN,
  environment,
  release,
  tags,
})

Sentry.init({
  // https://github.com/getsentry/sentry-javascript/issues/2027
  dsn: SENTRY_DSN,
  environment,
  release,
  tracesSampleRate: 0.005,
  integrations: [
    Sentry.breadcrumbsIntegration({
      console: false,
      dom: true,
      fetch: true,
      history: false,
      sentry: true,
      xhr: true,
    }),
  ],
  beforeSend: (event) => {
    const state = store.getState()

    const recipe = controlTree.getPublicRecipe(state)
    Sentry.setTag('recipe', recipe)

    return event
  },
})

Sentry.setTags(tags)
