import * as _ from '@technically/lodash'
import axios from 'axios'
import axiosRetry from 'axios-retry'

const MAX_RETRY_DELAY = 2 * 60 * 1000

const http = axios.create()

const randomRange = (min: number, max: number) =>
  Math.random() * (max - min) + min

axiosRetry(http, {
  retries: Infinity,
  // Retry even idempotent requests.
  retryCondition: (e) => {
    const status = e?.response?.status

    if (!status) {
      return true
    }

    return (
      // Server errors
      (status >= 500 && status < 600) ||
      // Request Timeout
      status === 408 ||
      // Too Many Requests
      status === 429
    )
  },
  // Exponential delay for retries.
  retryDelay: (attempt, e) => {
    const delay =
      Math.min(MAX_RETRY_DELAY, 2 ** attempt * 1000) * randomRange(0.75, 1)

    const { config } = e
    const { url, method } = config ?? {}

    const requestRepr = `${_.toUpper(method)} ${(url ?? '').replace(
      /\?.*$/,
      '?...',
    )}`
    const delayRepr = _.round(delay / 1000, 2)

    console.log(
      `Retrying request ${requestRepr} in ${delayRepr} seconds (attempt #${attempt})`,
    )

    return delay
  },
})

http.interceptors.request.use((config: any) => {
  if (!config['axios-retry']) {
    config.startTime = new Date()
  }

  return config
})

export default http
