import fp from 'lodash/fp.js'

import {
  getDualLayers,
  getTextLayer,
} from '~p/client/renderComposite/meshLayerUtils'

import { PROP_DEF_DICT, STAMP_DICT, COLOR_DICT } from '../common/sheets'

const toneLayer = (name, filename, bundleName) => ({
  name,
  filename,
  bundleName,
  blend: 'tone',
})

const shadowLayer = (name, filename, bundleName) => ({
  name,
  filename,
  bundleName,
  blend: 'shadow',
})

const regularLayer = (name, filename, bundleName) => ({
  name,
  filename,
  bundleName,
})

const fillLayer = (color, name, filename, bundleName) => {
  if (!color) {
    return null
  }
  return {
    name,
    filename,
    color,
    bundleName,
  }
}

const colorLayer = (color, name) => {
  if (color && color.props.texture) {
    return {
      filename: color.props.texture,
      mask: name,
    }
  }

  return fillLayer(color, name)
}

const textureMesh = (hasMesh, textureId, meshId, mask) => {
  if (!hasMesh) {
    return null
  }
  if (!textureId) {
    return null
  }
  if (!meshId) {
    return null
  }

  return {
    type: 'mesh3d',
    recipeData: {
      textureId,
      meshId,
    },
    mask,
  }
}

const textMesh = (hasMesh, color, text, font, meshId, mask) => {
  if (!hasMesh) {
    return null
  }
  if (!color) {
    return null
  }
  if (!text) {
    return null
  }
  if (!font) {
    return null
  }
  if (!meshId) {
    return null
  }
  return getTextLayer({
    meshId,
    color,
    textData: {
      text,
      font: typeof font === 'string' ? font : font.id,
    },
    mask,
  })
}

const stampLayers = (color, nameBase, filenameBase, bundleName, toneMethod) => {
  if (!color) {
    return null
  }
  const isMetallic = color.id !== 'pressed'
  return [
    toneMethod === 'shadow' &&
      shadowLayer(
        `${nameBase}-shadow`,
        `${filenameBase || nameBase}-pressed`,
        bundleName,
      ),
    toneMethod === 'tone' &&
      toneLayer(
        `${nameBase}-tone`,
        `${filenameBase || nameBase}-pressed`,
        bundleName,
      ),
    isMetallic &&
      regularLayer(
        `${nameBase}-regular`,
        `${filenameBase || nameBase}-${color.id}`,
        bundleName,
      ),
  ]
}

const combinedStamps = (serie, glove, gloveAsset, options) =>
  stampLayers(
    options.stamp || STAMP_DICT.pressed,
    'stamp-palm',
    glove.id,
    'mesh-stamp',
    'shadow',
  )

const fingerPadOrHood = (finger, stitchingColor) => {
  if (!finger || !finger.type) {
    return null
  }
  const fingerPrefix = finger.location.id.substr(0, 1)
  const typeSuffix = fp.upperFirst(finger.type.id)
  const basePath = `${fingerPrefix}Finger${typeSuffix}`
  return [
    fillLayer(finger.color, 'padOrHood-fill', basePath),
    fillLayer(stitchingColor, 'padOrHood-stitching', `${basePath}-stitching`),
    toneLayer('padOrHood-tone', `${basePath}-tone`),
  ]
}

const EXPORT_RIBBON = true
const pinkyRibbon = (ribbon) => {
  if (!ribbon) {
    return null
  }

  if (EXPORT_RIBBON) {
    return [
      fillLayer(ribbon, 'ribbon-fill', 'ribbon-fill', 'mesh-dual'),
      shadowLayer('ribbon-shadow', 'ribbon-shadow', 'mesh-dual'),
    ]
  }

  //  Explicit list of stamps for development purposes
  return getDualLayers({
    meshId: 'ribbon',
    textureIdBase: 'textures/ribbon/ribbon',
    color: ribbon,
  })
}

const embroideryLiner = (hasMesh, serie, liner) => {
  if (!serie) {
    return null
  }
  if (!PROP_DEF_DICT.embroidery_liner_text.availability[serie.id]) {
    return null
  }

  return liner && liner.text ?
      textMesh(
        hasMesh,
        liner.color,
        liner.text,
        liner.font,
        'liner',
        'liner-mask',
      )
    : regularLayer('embroideryLiner')
}

const numberCircle = (hasMesh, number, stitchingColor) => {
  if (!number) {
    return null
  }
  const { text, ringColor, color, font } = number
  if (!text || !color || !font) {
    return null
  }

  if (!ringColor) {
    return [textMesh(hasMesh, color, text, `${font.id}Number`, 'number')]
  }

  const { hasGoldOutline } = ringColor.props
  const fontName = `${font.id}Number${text.length > 1 ? 'Small' : ''}`

  return [
    fillLayer(ringColor, 'number-bg'),
    fillLayer(stitchingColor, 'number-stitching'),
    toneLayer('number-tone'),
    hasGoldOutline && regularLayer('number-goldCircle'),
    textMesh(hasMesh, color, text, fontName, 'number'),
  ]
}

const toneWithWelting = (serie, weltingType) => {
  const weltingTypeName = weltingType ? weltingType.id : 'null'
  const suffix = serie.props.toneByWeltingSuffix[weltingTypeName]
  if (!suffix) {
    return null
  }
  const filename = `tone-${suffix}`
  return toneLayer('tone', filename)
}

const meshBack = (color, stitchingColor) => {
  if (!color) {
    return null
  }

  return [
    fillLayer(color, 'meshBack'),
    toneLayer('tone-proMesh'),
    fillLayer(stitchingColor, 'meshBack-stitching'),
  ]
}

const proSelectLogo = (serie, patchColor) => [
  serie.props.baseId === 'proSelect' && regularLayer('proSelect-logo'),
  serie.props.baseId === 'proSelect' &&
    fillLayer(patchColor, 'proSelect-patch'),
]

const mizunoProLogo = (serie) => [
  serie.props.baseId === 'mizunoPro' && regularLayer('mizunoPro-logo'),
]

const customClassicLogo = (serie) => [
  serie.props.baseId === 'customClassic' && regularLayer('customClassic-logo'),
]

const webLogo = (serie, web, embroideryColor) => {
  const color =
    serie.id === 'customClassicFastpitch' ? COLOR_DICT.gold : embroideryColor
  return fillLayer(color, 'web-logo', `web-${web.id}-logo`)
}

const palm = (serie, gloveAsset, color) => {
  const palmLayerName =
    (
      serie.props.baseId === 'proSelect' &&
      gloveAsset.props.shapeId !== 'fielder'
    ) ?
      'proSelect-palm'
    : 'palm'
  return fillLayer(color, palmLayerName)
}

export const getLayerInstructions = (expandedRecipeNested, viewName) => {
  const {
    product = {},
    colors = {},
    embroidery = {},
    calc = {},
  } = expandedRecipeNested
  const options = expandedRecipeNested.options || {}
  const { gloveAsset, serie } = calc
  const { glove, web } = product

  switch (viewName) {
    case 'back':
      return [
        palm(serie, gloveAsset, colors.palm),
        fillLayer(colors.thumbLoop, 'thumbLoop'),
        fillLayer(colors.pinkyOutside, 'pinky'),
        fillLayer(colors.back, 'back'),
        fillLayer(colors.backPanel6, 'backPanel_6'),
        fillLayer(colors.backPanel5, 'backPanel_5'),
        fillLayer(colors.backPanel4, 'backPanel_4'),
        fillLayer(colors.backPanel3, 'backPanel_3'),
        fillLayer(colors.backPanel2, 'backPanel_2'),
        fillLayer(colors.backPanel1, 'backPanel_1'),
        fillLayer(colors.wrist, 'wrist'),
        stampLayers(
          colors.backEmboss,
          serie.props.baseId === 'proSelect' ?
            'emboss-proSelect'
          : 'emboss-mizunoPro',
        ),
        fillLayer(colors.accent, 'accent'),
        colorLayer(colors.welting && colors.welting.color, 'welting'),

        colorLayer(colors.binding, 'binding'),

        fillLayer(embroidery.color, 'embroideryLogo'),

        (glove.id === 'GMP2-335CC' ? !embroidery.pinky.flag : true) && [
          fillLayer(embroidery.color, 'handCrafted'),
          toneLayer('tone-handCrafted'),
        ],

        fillLayer(
          colors.stitching && colors.stitching.glove,
          serie.props.baseId === 'proSelect' ?
            'proSelect-stitching'
          : 'stitching',
        ),

        gloveAsset.props.webOnBackView && [
          fillLayer(colors.web, 'web-static-base'),
          fillLayer(
            colors.stitching && colors.stitching.web,
            'web-static-stitching',
          ),
          fillLayer(colors.lace.color, 'web-laces', 'web-static-laces'),
          toneLayer('web-tone', 'web-static-tone'),
        ],

        proSelectLogo(serie, embroidery.patch),

        meshBack(colors.meshBack, colors.stitching && colors.stitching.glove),

        embroidery.pinky.flag &&
          textureMesh(
            gloveAsset.props.meshes.back.flag,
            `textures/flag/${embroidery.pinky.flag.id}.png`,
            'flag',
          ),
        pinkyRibbon(embroidery.pinky.ribbon),

        fillLayer(
          colors.lace.color,
          serie.props.baseId === 'proSelect' ?
            'proSelect-gloveLaces'
          : 'gloveLaces',
        ),

        fillLayer(colors.pinkyLoop || colors.back, 'pinkyLoop'),

        toneWithWelting(serie, colors.welting && colors.welting.type),

        mizunoProLogo(serie),
        customClassicLogo(serie),

        fingerPadOrHood(
          options.finger,
          colors.stitching && colors.stitching.glove,
        ),

        regularLayer('insert'),

        textMesh(
          gloveAsset.props.meshes.back.playerName,
          embroidery.name.color || embroidery.color,
          embroidery.name.text,
          embroidery.name.font,
          'playerName',
        ),
      ]

    case 'web':
      return [
        palm(serie, gloveAsset, colors.palm),
        fillLayer(colors.thumbOutside, 'thumb'),
        fillLayer(colors.pinkyOutside, 'pinky'),
        fillLayer(colors.back, 'back'),
        fillLayer(colors.backPanel6, 'backPanel_6'),
        fillLayer(colors.backPanel5, 'backPanel_5'),
        fillLayer(colors.backPanel4, 'backPanel_4'),
        fillLayer(colors.backPanel3, 'backPanel_3'),
        fillLayer(colors.backPanel2, 'backPanel_2'),
        fillLayer(colors.backPanel1, 'backPanel_1'),
        fillLayer(colors.pinkyLoop, 'pinkyLoop'),

        textMesh(
          !colors.meshBack && gloveAsset.props.meshes.web.playerName,
          embroidery.name.color || embroidery.color,
          embroidery.name.text,
          embroidery.name.font,
          'playerName',
        ) || combinedStamps(serie, glove, gloveAsset, options),

        fillLayer(colors.wrist, 'wrist'),
        stampLayers(
          colors.backEmboss,
          serie.props.baseId === 'proSelect' ?
            'emboss-proSelect'
          : 'emboss-mizunoPro',
        ),
        fillLayer(colors.accent, 'accent'),
        colorLayer(colors.welting && colors.welting.color, 'welting'),
        colorLayer(colors.binding, 'binding'),
        fillLayer(embroidery.color, 'embroideryLogo'),

        fillLayer(
          colors.stitching && colors.stitching.glove,
          serie.props.baseId === 'proSelect' ?
            'proSelect-stitching'
          : 'stitching',
        ),

        fillLayer(colors.thumbLoop, 'thumbLoop'),
        fillLayer(
          colors.lace.color,
          serie.props.baseId === 'proSelect' ?
            'proSelect-gloveLaces'
          : 'gloveLaces',
        ),

        proSelectLogo(serie, embroidery.patch),

        toneWithWelting(serie, colors.welting && colors.welting.type),

        mizunoProLogo(serie),
        customClassicLogo(serie),

        meshBack(colors.meshBack, colors.stitching && colors.stitching.glove),

        colors.meshBack &&
          textMesh(
            gloveAsset.props.meshes.web.playerName,
            embroidery.name.color || embroidery.color,
            embroidery.name.text,
            embroidery.name.font,
            'playerName',
          ),

        embroideryLiner(
          gloveAsset.props.meshes.web.liner,
          serie,
          embroidery.liner,
        ),

        fingerPadOrHood(
          options.finger,
          colors.stitching && colors.stitching.glove,
        ),

        fillLayer(colors.web, 'web-base', `web-${web.id}-base`),
        fillLayer(
          colors.stitching && colors.stitching.web,
          'web-stitching',
          `web-${web.id}-stitching`,
        ),
        fillLayer(colors.lace.color, 'web-laces', `web-${web.id}-laces`),
        webLogo(serie, web, embroidery.color),
        toneLayer('web-tone', `web-${web.id}-tone`),

        regularLayer('insert'),

        numberCircle(
          gloveAsset.props.meshes.web.number,
          embroidery.number,
          colors.stitching && colors.stitching.glove,
        ),

        textureMesh(
          gloveAsset.props.meshes.web.number,
          embroidery.logo && embroidery.logo.previewFile,
          'number',
        ),

        // TODO verify where is the flag placed - assuming just pinky
      ]

    case 'palm':
      return [
        fillLayer(colors.web, 'web-base', `web-${web.id}-base`),
        fillLayer(
          colors.stitching && colors.stitching.web,
          'web-stitching',
          `web-${web.id}-stitching`,
        ),

        palm(serie, gloveAsset, colors.palm),
        fillLayer(colors.back, 'back'),
        fillLayer(colors.backPanel6, 'backPanel_6'),
        fillLayer(colors.backPanel5, 'backPanel_5'),
        fillLayer(colors.backPanel4, 'backPanel_4'),
        fillLayer(colors.backPanel3, 'backPanel_3'),
        fillLayer(colors.backPanel2, 'backPanel_2'),
        fillLayer(colors.backPanel1, 'backPanel_1'),
        // TODO Dmitry update assets so that if the panels are not rendered for
        // Mizuno Pro, we see palm color instead. The current logic checks for
        // existence of these layers for every glove, which is not desired
        fillLayer(colors.pinkyThumbPanels || colors.palm, 'pinkyThumbPanels'),
        fillLayer(colors.thumbOutside, 'thumb'),
        fillLayer(colors.pinkyOutside, 'pinky'),
        fillLayer(colors.accent, 'accent'),
        fillLayer(colors.thumbLoop, 'thumbLoop'),
        fillLayer(colors.pinkyLoop, 'pinkyLoop'),
        stampLayers(
          colors.backEmboss,
          serie.props.baseId === 'proSelect' ?
            'emboss-proSelect'
          : 'emboss-mizunoPro',
        ),
        colorLayer(colors.welting && colors.welting.color, 'welting'),
        serie.props.baseId === 'mizunoPro' &&
          gloveAsset.props.shapeId === 'catcher' &&
          fillLayer(colors.wrist, 'wrist'),
        colorLayer(colors.binding, 'binding'),

        fillLayer(
          colors.stitching && colors.stitching.glove,
          serie.props.baseId === 'proSelect' ?
            'proSelect-stitching'
          : 'stitching',
        ),

        gloveAsset.props.hasEmbroideryOnPalm &&
          fillLayer(embroidery.color, 'embroideryLogo'),

        combinedStamps(serie, glove, gloveAsset, options),

        fillLayer(colors.lace.color, 'gloveLaces'),

        toneWithWelting(serie, colors.welting && colors.welting.type),

        fillLayer(colors.lace.color, 'web-laces', `web-${web.id}-laces`),
        webLogo(serie, web, embroidery.color),
        toneLayer('web-tone', `web-${web.id}-tone`),

        meshBack(colors.meshBack, colors.stitching && colors.stitching.glove),

        regularLayer('insert'),
      ]

    default:
      return []
  }
}
