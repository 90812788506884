import React from 'react'

import { cn, mods } from '~p/client/components/utils'

import Subline from '../atoms/Subline'

import './TextInput.css'

const TextInput = (props) => {
  const {
    classMods,
    subline = '',
    value = '',
    maxLength,
    pattern,
    placeholder = '',
    type = 'text',
    autoComplete,
    autoFocus = false,
    change,
    onChange,
    onSubmit,
    onFocus,
    inputRef,
    primaryLabel,
    turingTest,
    ariaLabel,
  } = props

  const [isValid, setIsValid] = React.useState(true)
  const validate = (ev) => {
    const val = ev.target.value
    setIsValid(
      (val && pattern ? new RegExp(pattern).test(val) : true) &&
        (val && maxLength ? val.length <= maxLength : true),
    )
  }

  const changeHandler = (ev) => {
    if (change) {
      change(ev.target.value)
    } else if (onChange) {
      onChange(ev.target.value)
    }
    // change from invalid to valid as soon as the user fixes the input
    // (but only from valid to invalid when input loses focus i.e. onBlur)
    if (!isValid) {
      validate(ev)
    }
  }

  const onKeyDownHandler = (ev) => {
    if (ev.key === 'Enter') {
      if (onSubmit) {
        onSubmit()
      }
    }
  }

  return (
    <div
      className={cn([
        'textInput',
        mods(classMods),
        mods([!isValid && 'invalid']),
        mods(['test']),
        mods([turingTest && 'turingTest']),
      ])}
    >
      <label>
        {primaryLabel && (
          <strong className={cn(['textInput-primaryLabel'])}>
            {primaryLabel}
          </strong>
        )}

        <input
          ref={inputRef}
          className={cn(['textInput-input'])}
          type={type}
          autoComplete={autoComplete}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          value={value}
          onChange={changeHandler}
          onKeyDown={onKeyDownHandler}
          onFocus={onFocus}
          onBlur={validate}
          placeholder={placeholder}
          aria-label={ariaLabel}
        />
        <Subline classMods={classMods}>{subline}</Subline>
      </label>
    </div>
  )
}

export default TextInput
