import fp from 'lodash/fp.js'
import { createBrowserHistory as createHistory } from 'history'
import qs from 'qs'
import jsonStableStringify from 'json-stable-stringify'

// History needs working DOM.
const history = typeof window !== 'undefined' ? createHistory() : null

const stringifySearch = (d: Record<string, unknown>) =>
  d ?
    qs.stringify(
      fp.pickBy((v) => v, d),
      { encode: false, addQueryPrefix: true },
    )
  : ''

const parseSearch = (s: string, decode = true) =>
  qs.parse(s, {
    ignoreQueryPrefix: true,
    decoder: decode ? undefined : (x) => x,
  })

type LocationOptions = {
  path?: string
  query?: Record<string, string | number | undefined | null> | null
}

const getNextLocation = ({ path = '', query }: LocationOptions) => {
  const { location } = window

  const pathname = path || location.pathname

  const prevQuery = parseSearch(location.search)

  // When query is set to explicit null, remove it.
  // Otherwise merge with previous query.
  const nextQuery = query === null ? {} : { ...prevQuery, ...query }

  // When nextQuery contains ?changes, it should be manually handled.
  if (nextQuery.changes) {
    // When query.changes is given, it's an object already.
    // Otherwise it's aquired from location.search and must be parsed from JSON.
    const changes =
      query?.changes ? query.changes : JSON.parse(prevQuery.changes as string)

    nextQuery.changes = jsonStableStringify(changes, {
      replacer: (key, value) => {
        if (typeof value !== 'string') {
          return value
        }
        return encodeURIComponent(
          // https://stackoverflow.com/q/4253367/458610
          value.replace(/\\/g, '\\\\').replace(/"/g, '\\"'),
        )
      },
    })
  }

  let search = stringifySearch(nextQuery)

  const doNotDisableChanges = process.env.DO_NOT_DISABLE_CHANGES === '1'

  const nextUrl = `${location.origin}${pathname}${search}`
  // Handle very long URLs by removing ?changes.
  // Length of 2k should work in any browser: http://stackoverflow.com/a/417184/458610
  if (nextUrl.length > 2000 && !doNotDisableChanges) {
    console.info('Disabling ?changes because URL is too long', nextUrl)
    search = stringifySearch(fp.unset('changes', nextQuery))
  }

  return { pathname, search }
}

const push = (x: LocationOptions) => {
  if (!history) return
  history.push(getNextLocation(x))
}

const replace = (x: LocationOptions) => {
  if (!history) return
  history.replace(getNextLocation(x))
}

export { history, push, replace, stringifySearch, parseSearch }
