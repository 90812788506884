import { openUnhandledErrorAlert } from './common/actions'

const handleErrors = (store) => {
  window.addEventListener('error', (event) => {
    const errorMessage = event.error?.message || event.message

    if (event && errorMessage.includes('ResizeObserver')) {
      // https://github.com/juggle/resize-observer/blob/master/README.md#resize-loop-detection
      event.preventDefault()
      return
    }

    store.dispatch(openUnhandledErrorAlert())
  })

  window.addEventListener('unhandledrejection', (event) => {
    throw event
  })
}

export default handleErrors
