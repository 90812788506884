import fp from 'lodash/fp.js'

const devicePixelRatio =
  (typeof window !== 'undefined' && window.devicePixelRatio) || 1

const getPreferredSize = (heights, aspectRatio, containerSize) => {
  const sizes = fp.map(
    (height) => ({
      height,
      width: Math.round(height / aspectRatio),
    }),
    heights,
  )
  const reversedSizes = fp.reverse(sizes)

  let preferredSize = reversedSizes[0]
  for (const element of reversedSizes) {
    const isPreferred =
      element.width >= containerSize.width * devicePixelRatio ||
      element.height >= containerSize.height * devicePixelRatio
    if (isPreferred) {
      preferredSize = element
    }
  }

  return preferredSize
}

export default getPreferredSize
